<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" md="12">
        <v-card :elevation="1" color="#26c6da2b">
          <v-card-text class="py-1">
            <v-row dense>
              <v-col cols="12" md="2">
                <v-combobox label="Employee" dense outlined hide-details v-model="employee"
                  :items="$store.getters['employee/employees']" :loading="$store.getters['employee/loadingEmployees']"
                  item-text="display_name" item-value="id" class="mb-1 custom-form"></v-combobox>
              </v-col>

              <v-col cols="6" md="2">
                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on }">
                    <v-text-field dense outlined hide-details v-model="date_from" label="Date From" v-on="on"
                      class="custom-form"></v-text-field>
                  </template>
                  <v-date-picker v-model="date_from"></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="6" md="2">
                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on }">
                    <v-text-field dense outlined hide-details v-model="date_to" label="Date To" v-on="on"
                      class="custom-form"></v-text-field>
                  </template>
                  <v-date-picker v-model="date_to"></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="6" md="1">
                <v-btn elevation="1" small color="primary" dark style="margin-top: -2px" :loading="loading"
                  @click="getReports">
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="records.length > 0">
      <v-col cols="12">
        <v-btn color="primary" class="white--text" small dense @click="print">
          <v-icon left small dense> mdi-printer </v-icon>
          Print
        </v-btn>
      </v-col>
      <v-col cols="12" id="reportContent">
        <v-simple-table dense class="record_table" v-for="(record, sl) in records" :key="sl">
          <tr>
            <th colspan="12" style="text-align: center">
              {{ record.date | dateFormat }}
            </th>
          </tr>
          <tr class="record_heading_tr">
            <th>SL.</th>
            <th>Date</th>
            <th>Punch Time</th>
            <th>Device</th>
            <th>Status</th>
          </tr>

          <tr v-for="(data, sl2) in record.records" :key="data.emp_id">
            <td>{{ sl2 + 1 }}</td>
            <td>{{ data.date }}</td>
            <td>{{ data.punch_time | timeFormat }}</td>
            <td>{{ data.device_name }}</td>
            <td>{{ data.status }}</td>
          </tr>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import utility from "../../mixins/utility.mixin";
export default {
  mixins: [utility],
  data() {
    return {
      date_from: this.toISOLocal().substr(0, 10),
      date_to: this.toISOLocal().substr(0, 10),
      department: null,
      employee: null,
      records: [],
      user: {},
      loading: false,
    };
  },
  watch: {
    employee(e) {
      this.records = [];
    },
  },
  async created() {
    this.user = this.$store.getters['user/getUser'];
    if (this.user.role == 'User' || this.user.role == 'Entry User') {
      await this.$store.dispatch('department/getDepartments');
      this.department = this.$store.getters['department/departments'].find(item => item.id == this.user.department_id);
    }
    this.getEmployees();
  },
  methods: {
    getEmployees() {
      this.employee = null;
      this.$store.dispatch('employee/getEmployees', {
        apiParams: {
          departmentId: this.department?.id ?? null,
        },
      })
    },
    async getReports() {
      if (this.employee == null || this.employee.id == undefined) {
        alert('Select Employee');
        return;
      }
      this.loading = true;
      this.records = await this.$store.dispatch(
        "attendance/getAttendanceLogs",
        {
          employeeId: this.employee?.id ?? null,
          date_from: this.date_from,
          date_to: this.date_to,
        }
      );

      this.loading = false;
    },
    async print() {
      let title = `Attendance Log of ${this.employee.name} (${this.date_from} to ${this.date_to})`;

      let company_profile = this.$store.getters["company/company"];

      // Get HTML to print from element
      const prtHtml = document.getElementById("reportContent").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      const WinPrint = window.open("", "PRINT", `height=${screen.height}, width=${screen.width}`);
      WinPrint.document.write(`<!DOCTYPE html>
        <html>
        <head>
            ${stylesHtml}
        </head>
        <body>
            <div data-app="true" class="v-application v-application--is-ltr theme--light" id="app">
                <div class="container container--fluid">
                    <div class="row row--dense">
                        <div class="col-2 px-2">
                            <img style="width: 100%; height: 50px;" src="${this.$store.state.host +
        company_profile.logo
        }">
                        </div>
                        <div class="col-10 px-2 text-center">
                            <span class="font-weight-black">
                                ${company_profile.name}
                            </span>
                            <br>
                            <span>${company_profile.address}</span>
                            <br>
                            <span>
                                Email:${company_profile.email
        }, Contact:${company_profile.phone}
                            </span>
                        </div>
                        <div class="col-12 px-2 text-center">
                            <p class="font-weight-black custom_header">
                                ${title}
                            </p>
                        </div>
                        <div class="col col-12 px-2">
                            ${prtHtml}
                        </div>
                    </div>
                </div>
            </div>
        </body>
        </html>`);

      WinPrint.document.head.innerHTML += `
        <style>
            .v-data-table__wrapper{
                overflow: unset;
            }
            .record_heading_tr th{
                text-align: center !important;
            }
            .custom_header{
                background-color: #0000001f;
                border-radius: 4px;
                margin-bottom: 5px !important;
            }
            tr:hover{
                background: unset !important;
            }
            .record_heading_tr:hover{
                background: #4caf7773 !important;
            }
            @media print {
                table.record_table { page-break-after:auto }
                table.record_table tr    { page-break-inside:avoid; page-break-after:auto }
                table.record_table td    { page-break-inside:avoid; page-break-after:auto }
                table.record_table thead { display:table-header-group }
                table.record_table tfoot { display:table-footer-group }
            }
        </style>`;

      WinPrint.focus();
      WinPrint.document.close();
      await new Promise((resolve) => setTimeout(resolve, 2000));
      WinPrint.print();
      WinPrint.close();
    },
  },
};
</script>

<style scoped>
.record_table {
  margin-bottom: 5px;
}

.record_table table,
.record_table th,
.record_table td {
  border: 1px solid #dee2e6;
  border-collapse: collapse;
  padding: 0 !important;
}

.record_table th,
.record_table td {
  padding: 2px !important;
  font-size: 11px !important;
  text-align: center;
  height: 0 !important;
}

.record_heading_tr {
  background: #4caf7773;
}
</style>